// header scroll
const theme_url = "/wp-content/themes/kitanihon";

document.addEventListener("DOMContentLoaded", function () {
  const header = document.getElementById("js-header");
  // header.style.transition = "all 0.3s ease";
  window.addEventListener("scroll", function () {
    if (window.scrollY > 0) {
      if (!header.classList.contains("is-down")) {
        header.style.transition = "none";
        header.classList.add("is-down");
        void header.offsetHeight;
        header.style.transition = "";
      }
    } else {
      if (header.classList.contains("is-down")) {
        header.style.transition = "none";
        header.classList.remove("is-down");
        void header.offsetHeight;
        header.style.transition = "";
      }
    }
  });

  // SP ハンバーガーメニュー
  const openbtn = document.querySelector(".openbtn"); // Hamburger button
  const triggerBtn = document.querySelector(".header__trigger"); // Trigger for opening the menu
  // const navList = document.querySelector('.header__nav-list'); // Navigation menu

  // Toggle '-active' class for the hamburger menu
  triggerBtn.addEventListener("click", function () {
    openbtn.classList.toggle("-active"); // Toggle class on the hamburger button
    // navList.classList.toggle('-active'); // Toggle class on the navigation menu
  });

  // overlay menu

  // const openbtn = document.querySelector('.openbtn'); // Hamburger button
  const overlayMenu = document.querySelector(".overlay-menu"); // Overlay menu container
  const menuCloseButtons = document.querySelectorAll(".menu-close"); // All close buttons for overlay
  const menuItems = document.querySelectorAll(".menu-item"); // Main menu items
  const submenus = document.querySelectorAll(".submenu"); // All submenus
  const backButtons = document.querySelectorAll(".submenu-back"); // Back buttons in submenus
  const menuTitle = document.querySelector(".menu-title"); // Main menu title element
  const menuList = document.querySelector(".menu-list"); // Main menu list
  let submenuStack = []; // Stack to keep track of submenu navigation

  // Toggle overlay menu on hamburger click
  openbtn.addEventListener("click", function () {
    overlayMenu.classList.toggle("-active"); // Show or hide the overlay
    openbtn.classList.toggle("-active"); // Activate/deactivate the hamburger button
  });

  // Close all menus when any .menu-close is clicked
  menuCloseButtons.forEach((menuClose) => {
    menuClose.addEventListener("click", function () {
      resetMenu(); // Hide all menus and submenus
    });
  });

  // Show submenu on menu item click
  menuItems.forEach((item) => {
    item.addEventListener("click", function () {
      const submenuId = this.getAttribute("data-submenu"); // Get the associated submenu ID
      const submenu = document.getElementById(submenuId); // Get the submenu element

      if (submenu) {
        submenuStack.push(menuList); // Push the main menu to stack
        menuList.style.display = "none"; // Hide the main menu

        showSubmenu(submenu); // Show the selected submenu
      }
    });
  });

  // Back button functionality in submenus
  backButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const currentSubmenu = this.closest(".submenu");
      hideSubmenu(currentSubmenu); // Hide current submenu

      const previousMenu = submenuStack.pop(); // Get the previous menu
      if (previousMenu) {
        if (previousMenu === menuList) {
          menuTitle.textContent = "メニュー"; // Set back to main menu title
        }
        previousMenu.style.display = "block"; // Show previous menu
      }
    });
  });

  // Helper function to show a submenu
  function showSubmenu(submenu) {
    const submenuTitle = submenu.querySelector(".submenu-title");
    menuTitle.textContent = submenuTitle.textContent; // Update title
    submenu.classList.add("-active"); // Show submenu
  }

  // Helper function to hide a submenu
  function hideSubmenu(submenu) {
    submenu.classList.remove("-active");
  }

  // Reset all menus
  function resetMenu() {
    overlayMenu.classList.remove("-active"); // Hide overlay menu
    submenus.forEach((submenu) => submenu.classList.remove("-active")); // Hide all submenus
    menuList.style.display = "block"; // Show main menu list
    menuTitle.textContent = "メニュー"; // Reset the main menu title
    openbtn.classList.remove("-active"); // Deactivate the hamburger button
    submenuStack = []; // Clear submenu stack
  }
});
